import React, {useEffect, useState} from 'react';
import MainButton from "../../mainButton";
import Modal, {ModalBody, ModalHeader} from "../../Modal";
import Link from "next/link";

const AboutVideo = ({t}) => {

    const about_video = t('common:about_video', {}, {returnObjects: true});

    const [isVideoPlay, setIsVideoPlay] = useState(false);
    const [videoElement, setVideoElement] = useState(undefined);
    // const [isVideoPlay, setIsVideoPlay] = useState(false);

    useEffect(() => {

        setVideoElement(document.getElementById('promo_video'))


    }, [])

    const addControls = (el) => {
        el.setAttribute('controls', 'controls')
    }
    const removeControls = (el) => {
        el.removeAttribute('controls')
    }

    const videoPlay = () => {
        videoElement.addEventListener('mouseover', () => addControls(videoElement))
        videoElement.addEventListener('mouseleave', () => removeControls(videoElement))
        if (videoElement.paused) {
            videoElement.play();
            setIsVideoPlay(true)
        } else {
            videoElement.pause();
            setIsVideoPlay(false)
        }
    }


    return (
        <section className='about_video_section' id='about'>
            {/*<div className="container">*/}
                <div className="about_video_section_wrapper">
                    <h2 className='title grey_gradient'>{about_video.title_1}<span
                        className='gradient_text'>{about_video.title_2}</span></h2>
                    <p className='subtitle'>{about_video.sub_title}</p>
                    <div className='video_content'>
                        <video id='promo_video' src="/video/video.mp4" poster="/video/poster.webp">
                            Sorry, your browser doesn't support embedded videos,
                            but don't worry, you can <a href="/video/video.mp4">download it</a>
                            and watch it with your favorite video player!
                        </video>
                        <div
                            className={`play_btn ${isVideoPlay ? 'playing' : ''}`}
                        >
                            <img width={77} height={81} onClick={() => {
                                videoPlay()
                            }} alt={'about PARLINE'}
                                 src={isVideoPlay ? '/video/pause.svg' : '/video/play.svg'}/>

                        </div>
                    </div>
                    <Link
                        className='video_cta'
                        prefetch={false}
                        href={process.env.NEXT_PUBLIC_SIGN_UP_URL}
                    >
                        <MainButton
                            text={about_video.button}
                        />
                    </Link>
                </div>
            {/*</div>*/}
        </section>
    );
}

export default AboutVideo;