import React from 'react';
import MainButton from "../../mainButton";
import Link from "next/link";

const SmallJoin = ({t}) => {

    const small_join = t('common:small_join', {}, {returnObjects: true});

    return (
        <section className='small_join_section'>
            <div className="container">
                <div className="small_join_section_wrapper">
                    <Link
                        className=''
                        prefetch={false}
                        href={process.env.NEXT_PUBLIC_SIGN_UP_URL || 'https://app.parline.io/sign-up'}
                    >
                    <MainButton
                        text={small_join.button}
                    />
                    </Link>
                    <div className='small_join_section_body'>
                        <p className='body_main'>{small_join.body}</p>
                        <span className='body_sub'>{small_join.join}</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SmallJoin;