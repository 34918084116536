import React from 'react';

const TokenDetails = ({t}) => {

    const token_details = t('common:token_details', {}, {returnObjects: true})

    const DetailsItem = ({item}) => {
        return <li className='group_list_item'>
            {(item.value_1 || item.value) ? <h4 className='group_list_item_value'>{item.value_1 || item.value}<span
                className='small_font'>{item.value_2}</span></h4> :
                <h4 className='group_list_item_value img'><img width="48" height="42" className='group_list_item_value_img' src={`/img/pages/home/tokenomics/${item.img_src}.svg`} alt={'PARLINE initial Chain'}/><span
                    className='small_font_white'>{item.value_2}</span></h4>
            }
            <span className='group_list_item_label'>{item.label}</span>
        </li>
    }

    return (
        <section className='token_details_section'>
            <div className="container">
                <div className='rocket_img'>
                    <picture>
                        <source
                            srcSet={`/img/pages/home/tokenomics/rocket.webp`}
                            type="image/webp"
                        />
                        <img
                            src="/img/pages/home/tokenomics/rocket.png"
                            alt="Buy PRLN Token"
                            width={645}
                            loading="lazy"
                            height={645}
                            className='rocket_img_img'
                        />
                    </picture>
                </div>
                <div className="token_details_section_wrapper">
                    <div className="details_group group">
                        <div className='group_title'>
                            <h3 className='group_title_title grey_gradient first_title'>{token_details.details.title}</h3>
                        </div>
                        <ul className='group_list'>
                            {token_details.details.list.map(item => {
                                return <DetailsItem
                                    key={item.label}
                                    item={item}
                                />
                            })}
                        </ul>
                    </div>
                    <div className="taxes_group group">
                        <div className='group_title'>
                            <h3 className='group_title_title grey_gradient'>{token_details.taxes.title}</h3>
                            <p className='group_title_subtitle'>{token_details.taxes.sub_title}</p>
                        </div>
                        <ul className='group_list'>
                            {token_details.taxes.list.map(item => {
                                return <DetailsItem
                                    key={item.label}
                                    item={item}
                                />
                            })}
                        </ul>
                    </div>
                    <div className="description_group group">
                        <div className='group_title'/>
                        <p className='group_list last'>{token_details.description}</p>
                    </div>
                    <div className='voley_img'>
                        <picture>
                            <source
                                media="(max-width: 767px)"
                                srcSet={`/img/main/display_none.png`}
                            />
                            <source
                                srcSet={`/img/pages/home/tokenomics/coins.webp`}
                                type="image/webp"
                            />
                            <img
                                src="/img/pages/home/tokenomics/coins.png"
                                alt="Buy PRLN Token"
                                width={591}
                                height={591}
                                className='rocket_img_img'
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TokenDetails;