import React, {useState, useEffect} from 'react';
import Progress from "./progress";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade, Autoplay} from "swiper";
import 'swiper/css/effect-fade'
import Link from "next/link";
import MainButton from "../../mainButton";

const Banner = ({t, apiData}) => {

    const banner = t('common:banner', {}, {returnObjects: true})

    const img = [
        {
            src: 'man_2'
        }, {
            src: 'man'
        }, {
            src: 'man_3'
        }
    ]

    return (
        <section className='banner_section'>
            <div className='container'>
                <div className="banner_section_wrapper">
                    <div className="title_block">
                        <h1 className='title_block_title'>
                            <span>{banner.title_1}</span>
                            <span>{banner.title_2}</span>
                            <span>{banner.title_3}<span className='gradient_text'>{banner.title_4}</span></span>
                        </h1>
                        <p className='title_block_sub'>{banner.sub_title}</p>
                        <div className='title_block_cta'>
                            <Link
                                className=''
                                prefetch={false}
                                href={process.env.NEXT_PUBLIC_SIGN_UP_URL || 'https://app.parline.io/sign-up'}
                            >
                                <MainButton
                                    text={banner.button}
                                />
                            </Link>
                            <Link
                                target='_blank'
                                className=''
                                prefetch={false}
                                href={process.env.NEXT_PUBLIC_WHITE_PAPER_URL || 'https://whitepaper.parline.io/'}
                            >
                                <MainButton
                                    outline
                                    text={banner.wp}
                                />
                            </Link>
                        </div>
                        <div className='title_block_audit'>

                            <svg width="62" height="63" viewBox="0 0 62 63" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.7149 47.4761C16.8006 45.419 11.9435 41.9904 8.81965 38.3904C8.15298 37.6285 7.6387 36.9999 7.67679 36.9618C7.69584 36.9428 8.95298 36.7904 10.4768 36.5999C17.5435 35.819 24.2292 33.8761 30.572 30.8095C36.8197 27.7809 41.2958 24.6952 45.8863 20.238C47.0673 19.0761 48.0006 18.2952 47.9435 18.5047C47.8863 18.7142 47.7911 19.3999 47.7149 20.0285C47.3911 23.1523 46.1339 27.0952 44.3625 30.6952C41.0863 37.3238 35.8101 42.6952 29.2958 46.0476C25.4101 48.0285 23.772 48.3333 21.7149 47.4761Z"
                                    fill="#22D8BE"/>
                                <path
                                    d="M4.80049 32.943C2.49573 29.0192 0.952869 24.6573 0.286203 20.1049C0.0766788 18.5811 0.000488281 16.4477 0.000488281 11.6096C0.000488281 5.1906 0.000488281 5.13346 0.438584 4.48584C0.667155 4.14298 1.14335 3.66679 1.46715 3.43822C2.62906 2.65726 9.61954 1.05726 14.9529 0.371551C18.7814 -0.123687 29.4481 -0.123687 33.1433 0.371551C37.3529 0.942979 42.6481 2.04774 45.4291 2.92393C46.8957 3.38107 48.0005 4.60012 48.0005 5.74298C48.0005 6.3525 45.391 10.2573 42.9148 13.362C39.8481 17.2096 35.2957 21.6287 31.4862 24.4287C25.8672 28.6001 18.9719 31.7239 12.0957 33.2477C10.5529 33.5906 6.47668 34.2192 5.82906 34.2192C5.67668 34.2192 5.21954 33.6477 4.80049 32.943ZM3.42906 12.1239C4.07668 9.74298 5.86716 7.30488 7.84811 6.14298C9.44811 5.20965 14.1148 4.25727 22.3814 3.17155L26.5719 2.61917L23.9053 2.69536C18.4005 2.86679 9.69573 4.33346 4.85763 5.87631C3.58144 6.29536 3.50525 6.3525 3.04811 7.26679C2.61001 8.14298 2.57192 8.40965 2.57192 10.9811C2.57192 12.5049 2.64811 14.3525 2.74335 15.0763L2.93382 16.4096L3.06716 14.6954C3.14335 13.762 3.29573 12.6001 3.42906 12.1239Z"
                                    fill="#22D8BE"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M44 56.5714C50.3907 56.5714 55.5714 51.3907 55.5714 45C55.5714 38.6093 50.3907 33.4286 44 33.4286C37.6093 33.4286 32.4286 38.6093 32.4286 45C32.4286 51.3907 37.6093 56.5714 44 56.5714Z"
                                      fill="#3366FF"/>
                                <path opacity="0.12" fill-rule="evenodd" clip-rule="evenodd"
                                      d="M44 63C53.9411 63 62 54.9411 62 45C62 35.0589 53.9411 27 44 27C34.0589 27 26 35.0589 26 45C26 54.9411 34.0589 63 44 63Z"
                                      fill="#3366FF"/>
                                <rect x="42.9381" y="50.3842" width="1.78022" height="12.3612" rx="0.89011"
                                      transform="rotate(-140.659 42.9381 50.3842)" fill="white"/>
                                <rect x="44.0643" y="49.1455" width="1.78022" height="7.12088" rx="0.89011"
                                      transform="rotate(135 44.0643 49.1455)" fill="white"/>
                            </svg>
                            {banner.audit}
                            <Link
                                target='_blank'
                                className=''
                                prefetch={false}
                                href={'https://www.cyberscope.io/audits/1-prln'}
                            >
                                Cyberscope
                            </Link>
                        </div>
                    </div>
                    <div className="image_block">
                        <div className='features_slider_wrapper'>
                            <div className='features_slider'>
                                <Swiper
                                    effect={'fade'}
                                    speed={2000}
                                    loop={true}
                                    breakpoints={{
                                        576: {
                                            slidesPerView: 1,
                                        }
                                    }}
                                    autoplay={{
                                        delay: 5000,
                                    }}
                                    lazy={false}
                                    modules={[EffectFade, Autoplay]}
                                    slidesPerView={1}
                                >
                                    {img.map(slide => {
                                        return (
                                            <SwiperSlide key={slide.src}>
                                                <picture>
                                                    <source
                                                        media="(max-width: 768px)"
                                                        srcSet={`/img/pages/home/banner/${slide.src}_small.webp`}
                                                        type="image/webp"
                                                    />
                                                    <source
                                                        media="(min-width: 769px)"
                                                        srcSet={`/img/pages/home/banner/${slide.src}.webp`}
                                                        type="image/webp"
                                                    />
                                                    <source
                                                        media="(max-width: 768px)"
                                                        srcSet={`/img/pages/home/banner/${slide.src}_small.png`}
                                                    />
                                                    <source
                                                        media="(min-width: 769px)"
                                                        srcSet={`/img/pages/home/banner/${slide.src}.png`}
                                                    />
                                                    <img
                                                        src={`/img/pages/home/banner/${slide.src}.png`}
                                                        alt="Buy PARLINE"
                                                        width={1046}
                                                        height={992}
                                                        className='image_block_image'
                                                    />
                                                </picture>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <Progress
                    t={t}
                    apiData={apiData}
                />
            </div>
        </section>
    );
};

export default Banner;