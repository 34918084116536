export const numberWithCommas = number => {
    if(number || number === 0) {
        let num = Number(number).toFixed(2);
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return number
    }
};

export const numberWithCommasNull = number => {
    if(number || number === 0) {
        let num = Number(number).toFixed(0);
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return number
    }
};