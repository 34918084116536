
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useEffect, useState } from 'react';
import MainLayout from "../layouts/main";
import useTranslation from "next-translate/useTranslation";
import Header from "../components/header";
import Banner from "../components/mainPage/Banner";
import Features from "../components/mainPage/Features";
import Tokenomics from "../components/mainPage/Tokenomics";
import TokenDetails from "../components/mainPage/TokenDetails";
import Roadmap from "../components/mainPage/Roadmap";
import Team from "../components/mainPage/Team";
import Faqs from "../components/mainPage/FAQ";
import Join from "../components/mainPage/Join";
import Footer from "../components/footer";
import Loader from "../components/Loader";
import AboutVideo from "../components/mainPage/Video";
import AsSeen from "../components/mainPage/AsSeen";
import PoweredBy from "../components/mainPage/PoweredBy";
import SmallJoin from "../components/mainPage/SmallJoin";
import WhatCanYouDo from "../components/mainPage/WhatCanYouDo";
import 'swiper/css';
import "swiper/css/scrollbar";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
export default function Home() {
    const { t } = useTranslation('common');
    const seo = t('common:seo', {}, { returnObjects: true });
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [apiData, setApiData] = useState(undefined);
    const getData = async () => {
        const res = process.env.NEXT_PUBLIC_ENV === 'dev' ? await fetch('/statistic') : await fetch(process.env.NEXT_PUBLIC_MAIN_API_URL);
        return await res.json();
    };
    useEffect(() => {
        let appLoading = new Promise((resolve, reject) => {
            setTimeout(resolve, 2000, true);
        });
        let apiData = getData().then(data => {
            setApiData(data);
        }).catch(() => {
            setApiData({
                percent_tokens: 0,
                stage_name: "Stage 1",
                stage_tokens: 0,
                token_price: 0.005,
                tokens_sold: 0,
                total_tokens: 0
            });
        });
        Promise.all([appLoading, apiData]).then(values => {
            setIsLoading(false);
            setLoaded(true);
        });
    }, []);
    return (<MainLayout title={seo.index_page.title} description={seo.index_page.description}>
            <Header t={t}/>
            <main className='main'>
                <Banner t={t} apiData={apiData}/>
                <AsSeen t={t}/>
                <div className='shadow'>
                    <div className='left_shadow'>
                        <div className='left_shadow_inner'>
                            <div className='left_shadow_inner_circle'/>
                        </div>
                    </div>
                    <div className='right_shadow'>
                        <div className='right_shadow_inner'>
                            <div className='right_shadow_inner_circle'/>
                        </div>
                    </div>
                    <div className='network_bg'>
                        <div className="container">
                            <AboutVideo t={t}/>
                            <WhatCanYouDo t={t}/>
                        </div>
                    </div>
                    <PoweredBy t={t}/>
                </div>
                <Features t={t}/>
                <SmallJoin t={t}/>
                <TokenDetails t={t}/>
                <Tokenomics t={t}/>

                <section className='roadmap_team_wrapper'>
                    <Roadmap t={t}/>
                </section>
                <Team t={t}/>
                <Faqs t={t}/>
                <Join t={t}/>
            </main>
            <Footer full={true} t={t}/>
            <Loader isLoading={isLoading} loaded={loaded}/>
        </MainLayout>);
}

    async function __Next_Translate__getStaticProps__18e99a74b04__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18e99a74b04__ as getStaticProps }
  