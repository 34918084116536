import React from 'react';
import Accordion from "../../accordion";

const Faqs = ({t}) => {

    const faqs = t('common:faqs', {}, {returnObjects: true});

    return (
        <section className='faqs_section' id='faq'>
            <div className="container">
                <div className="faqs_section_wrapper">
                    <h2
                        className="faqs_section_title grey_gradient"
                    >
                        {faqs.title}
                    </h2>
                    <Accordion
                        faqs_list={faqs.data}
                    />
                </div>
            </div>
        </section>
    );
};

export default Faqs;