import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Keyboard, Navigation, Pagination, Scrollbar, FreeMode} from "swiper";

const Features = ({t}) => {

    const features = t('common:features', {}, {returnObjects: true})

    return (
        <section className='features_section' id={'features'}>
            <div className="container" id={'features'}>
                <div className="features_section_wrapper">
                    <h2 className='features_section_title'>
                        <span className='title_1'>{features.title_1}</span>
                        <span className='title_2 gradient_text'>{features.title_2}</span>
                    </h2>
                    <div className='features_slider_wrapper'>
                        <div className='features_slider'>
                            <Swiper
                                keyboard={{
                                    enabled: true,
                                    onlyInViewport: true
                                }}
                                initialSlide="-1"
                                loop={true}
                                centeredSlides={false}
                                grabCursor={true}
                                cssMode={true}
                                spaceBetween={10}
                                mousewheel={true}
                                autoplay={true}
                                navigation={{clickable: true}}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 5,
                                    }
                                }}
                                lazy={false}
                                modules={[Navigation, Scrollbar, Keyboard]}
                                slidesPerView={5}
                            >
                                {features.items.map(slide => {
                                    return (
                                        <SwiperSlide key={slide.key}>
                                            <div className='features_slider_slide'>
                                                <img
                                                    src={`/img/pages/home/features/icon_${slide.key}.svg`}
                                                    alt={'slide.alt'}
                                                    width={24}
                                                    height={24}
                                                    className='slide_info_img'
                                                />
                                                <h3 className='slide_info_title'>{slide.title}</h3>
                                                <p className='slide_info_text'>{slide.text}</p>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;