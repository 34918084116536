import React, {useEffect, useState} from 'react';

const WhatCanYouDo = ({t}) => {

    const what = t('common:what', {}, {returnObjects: true})
    const [date, setDate] = useState('')

    useEffect(() => {
        setDate(new Date().toLocaleDateString())
    }, [])
    return (
        <section className='what_section' id={'ecosystem'}>
            <div className="what_section_wrapper">
                <h2 className='what_section_title'>
                    <span className='title_1 grey_gradient'>{what.title_1}</span>
                    <span className='title_2_scope'>
                            <span className='title_2 gradient_text'>{what.title_2}</span>
                        </span>
                </h2>
                <p className='about_section_subtitle'>{what.sub}</p>
                <div className="grid">
                    <div className="div1 grid_item p2p">
                        <h3>{what.items.p2p.title_1} {what.items.p2p.title_2}<span
                            className=''>{what.items.p2p.title_3}</span>
                        </h3>
                        <p>{what.items.p2p.description}</p>
                        <img
                            src={`/img/main/logo_part.svg`}
                            alt="Buy PARLINE"
                            width={19}
                            height={13}
                            className='p2p_img'
                        />
                    </div>
                    <div className="div6 grid_item p_t_e">
                        <h3 className=''>{what.items.p_t_e.title}</h3>
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/what/joystick_small.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/what/joystick.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/what/joystick_small.png`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/what/joystick.png`}
                            />
                            <img
                                src={`/img/pages/home/what/joystick.png`}
                                alt="Buy PARLINE"
                                width={642}
                                height={638}
                                className=''
                            />
                        </picture>
                    </div>
                    <div className="div4 grid_item fantasy">
                        <h3>{what.items.fantasy.title}</h3>
                        <div className='pts'>
                            <span>32</span>
                            PTS
                        </div>
                    </div>
                    <div className="div5 grid_item nft">
                        <h3 className=''>NFT<br/> {what.items.nft.title}</h3>
                    </div>
                    <div className="div8 grid_item stat">
                        <div className="stat_data">
                            <h3 className='stat_data_title'>{what.items.stat.basketball}</h3>
                            <p className='stat_data_date'>{date}, {what.items.stat.base}</p>
                            <div className="stat_data_items">
                                <div className="item">
                                    <span>{what.items.stat.avg}</span>
                                    <span>USD 120.35</span>
                                </div>
                                <div className="item">
                                    <span>{what.items.stat.fantasy}</span>
                                    <span>{what.items.stat.mini}</span>
                                </div>
                                <div className="item">
                                    <span className='item_green'>+2 pts</span>
                                    <span className='item_green'>+100 {what.items.stat.credits}</span>
                                </div>
                            </div>
                            <div className="stat_data_balance">
                                <div className="img_wrapper">
                                    <img width="19" height="13" src={'/img/main/logo_part.svg'} alt='Parline'/>
                                </div>
                                <span>{what.items.stat.own} 264$PRLN</span>
                            </div>
                        </div>
                    </div>
                    <div className="grid_item role bookie">
                        <picture>
                            <source
                                srcSet={`/img/pages/home/what/bookie.webp`}
                                type="image/webp"
                            />
                            <img
                                src={`/img/pages/home/what/bookie.png`}
                                alt="Bookie PARLINE Role"
                                width={70}
                                height={70}
                                className='role_img'
                            />
                        </picture>
                        <div>
                            <h3>{what.items.bookie.title}</h3>
                            <p>{what.items.bookie.description}</p>
                        </div>
                    </div>
                    <div className="grid_item role lp">
                        <picture>
                            <source
                                srcSet={`/img/pages/home/what/lp.webp`}
                                type="image/webp"
                            />
                            <img
                                src={`/img/pages/home/what/lp.png`}
                                alt="LP PARLINE Role"
                                width={138}
                                height={138}
                                className='image_block_image'
                            />
                        </picture>
                        <div>
                            <h3>{what.items.lp.title}</h3>
                            <p>{what.items.lp.description}</p>
                        </div>
                    </div>
                    <div className="grid_item role player">
                        <picture>
                            <source
                                srcSet={`/img/pages/home/what/player.webp`}
                                type="image/webp"
                            />
                            <img
                                src={`/img/pages/home/what/player.png`}
                                alt="Player PARLINE Role"
                                width={138}
                                height={138}
                                className='image_block_image'
                            />
                        </picture>
                        <div>
                            <h3>{what.items.player.title}</h3>
                            <p>{what.items.player.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatCanYouDo;