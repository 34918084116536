import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Keyboard, Navigation, Scrollbar} from "swiper";

const Team = ({t}) => {

    const team = t('common:team', {}, {returnObjects: true})

    return (
        <section className='team_section' id={'team'}>
            <div className="container">
                <div className="team_section_wrapper">
                    <div className='team_section_title'>
                        <h2 className='title'>
                            <span className='title_1 grey_gradient'>{team.title_1}</span>
                            <span className='title_2 gradient_text'>{team.title_2}</span>
                        </h2>
                        <p className='description'>{team.description}</p>
                    </div>
                    <div className='team_slider_wrapper'>
                        <div className='team_slider'>
                            <Swiper
                                keyboard={{
                                    enabled: true,
                                    onlyInViewport: true
                                }}
                                centeredSlides={false}
                                grabCursor={true}
                                cssMode={true}
                                spaceBetween={12}
                                mousewheel={true}
                                autoplay={true}
                                navigation={{clickable: true}}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 6,
                                    }
                                }}
                                lazy={true}
                                modules={[Navigation, Scrollbar, Keyboard]}
                                slidesPerView={6}
                            >
                                {team.team.map(slide => {
                                    return (
                                        <SwiperSlide key={slide.key}>
                                            <div className='team_slider_slide'>
                                                <picture>
                                                    <source
                                                        srcSet={`/img/pages/home/roadmap_team/team/${slide.img}.webp`}
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src={`/img/pages/home/roadmap_team/team/${slide.img}.png`}
                                                        alt={'slide.alt'}
                                                        width={300}
                                                        height={300}
                                                        className='slide_img'
                                                    />
                                                </picture>
                                                <div className='slide_info'>
                                                    <h3 className='slide_info_title'>{slide.name}</h3>
                                                    <p className='slide_info_text'>{slide.position}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;