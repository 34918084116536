import React from 'react';

const Roadmap = ({t}) => {

    const roadmap = t('common:roadmap', {}, {returnObjects: true})

    return (
        <section className='roadmap_section' id={'roadmap'}>
            <div className='container'>
                <h2 className='roadmap_section_title grey_gradient'>{roadmap.title}</h2>
                <div className="roadmap_section_wrapper">
                    <div className='bg_img'>
                        <picture>
                            <source
                                media="(max-width: 767px)"
                                srcSet={`/img/main/display_none.png`}
                            />
                            <source
                                srcSet={`/img/pages/home/roadmap_team/r_m_bg.webp`}
                                type="image/webp"
                            />
                            <img
                                src="/img/pages/home/roadmap_team/r_m_bg.png"
                                alt="Buy PRLN Token"
                                width={1200}
                                height={410}
                                className='rocket_img_img'
                            />
                        </picture>
                    </div>
                    {roadmap.list.map(el => {
                        return <div className={`map_item item_${el.key} ${el.status}`} key={el.key}>
                            <div className='map_item_wrapper'>
                                <h3>{el.title}</h3>
                                <ul className='map_item_list'>
                                    {el.body.map((el, i) => {
                                        return <li key={el.text + i} className={el.status}>{el.text}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </section>
    );
};

export default Roadmap;