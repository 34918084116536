import React from 'react';

const PoweredBy = ({t}) => {

    const powered_by = t('common:powered_by', {}, {returnObjects: true});

    const items = [
        {name: 'polygon', w: '34', h: '30'},
        {name: 'unreal', w: '30', h: '30'},
        {name: 'trust', w: '25', h: '30'},
        {name: 'aws', w: '50', h: '30'}
    ]

    return (
        <section className='powered_by'>
            <div className='container'>
                <div className='powered_by_wrapper'>
                    <h2 className='powered_by_title'>{powered_by.title}</h2>
                    <div className='powered_by_items'>
                        {items.map(el => {
                            return <div key={el.name} className='powered_by_item'>
                                <img
                                    src={`/img/pages/home/powered_by/${el.name}.svg`}
                                    alt={`PARLINE Token Powered By`}
                                    width={el.w}
                                    height={el.h}
                                    className=''
                                />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PoweredBy;