import React from 'react';
import MainButton from "../../mainButton";
import Link from "next/link";

const Join = ({t}) => {

    const join = t('common:join', {}, {returnObjects: true});

    return (
        <section className='join_section'>
            <div className="container">
                <div className="join_section_padding">
                <div className="join_section_wrapper">
                    <div className='join_section_title'>
                        <h2 className='title grey_gradient'>{join.title}</h2>
                        <p className='subtitle'>{join.sub_title}</p>
                        <Link
                            className=''
                            prefetch={false}
                            href={process.env.NEXT_PUBLIC_SIGN_UP_URL || 'https://app.parline.io/sign-up'}
                        >
                        <MainButton
                            text={join.button}
                        />
                        </Link>
                    </div>
                    <div className='join_section_image'>
                        <picture>
                            <source
                                media="(max-width: 991px)"
                                srcSet={`/img/main/display_none.png`}
                            />
                            <source
                                srcSet={`/img/pages/home/join/boxing.webp`}
                                type="image/webp"
                            />
                            <img
                                src="/img/pages/home/join/boxing.png"
                                alt="JOIN PARLINE"
                                loading="lazy"
                                width={567}
                                height={567}
                                className='boxing_image'
                            />
                        </picture>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
};

export default Join;