import React from 'react';

const AsSeen = ({t}) => {

    const as_seen = t('common:as_seen', {}, {returnObjects: true});

    const items = [
        {name: 'analytics', w: '150', h: '20'},
        {name: 'btc_com', w: '144', h: '20'},
        {name: 'blockzeit', w: '88', h: '20'},
        {name: 'benzinga', w: '111', h: '20'},
        {name: 'yahoo', w: '60', h: '20'},
        {name: 'wing', w: '22', h: '20'}
    ]

    return (
        <section className='as_seen'>
            <div className='container'>
                <div className='as_seen_wrapper'>
                    <h2 className='as_seen_title'>{as_seen.title}</h2>
                    <div className='as_seen_items'>
                        {items.map(el => {
                            return <div key={el.name} className='as_seen_item'>
                                <img
                                    src={`/img/pages/home/as_seen/${el.name}.svg`}
                                    alt={`PARLINE Partner`}
                                    width={el.w}
                                    height={el.h}
                                    className=''
                                />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AsSeen;