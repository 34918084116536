import React, {useState} from 'react';
import Link from "next/link";

const AccordionItem = ({item, index, activeIndex, setActiveIndex}) => {

    return <li
        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay="0"
    >
        <div
            onClick={(e) => {
                if (!activeIndex || activeIndex !== index) {
                    setActiveIndex(index)
                } else {
                    setActiveIndex(null)
                }
            }}
            className={`accordion_item_wrapper ${activeIndex === index ? 'is_active' : ''}`}
        >
            <div className='accordion_item'>
                <h3 className='accordion_item_title'>{item.title}</h3>
                <span className='accordion_item_icon'>{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`accordion_item_body`}>
                {item.body}
                {item.body_email ? <Link
                    style={{color: '#61FF88'}}
                    className=''
                    prefetch={false}
                    href={`mailto:${item.body_email}`}
                >{item.body_email}</Link> : null}
                {item.body_1 ? item.body_1 : null}
            </div>
        </div>
    </li>
}

const Accordion = ({faqs_list}) => {

    const [activeIndex, setActiveIndex] = useState(null)

    return (
        <div className='accordion_container'>
            <ul className='accordion_data'>
                {faqs_list.map((item) => {
                    return <AccordionItem
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        index={item.index}
                        key={item.index}
                        item={item}
                    />
                })}
            </ul>
        </div>
    );
};

export default Accordion;