import React, {useEffect, useState} from "react";
import ProgressLine from "../../progress";
import {numberWithCommasNull} from "../../../helpers/numberWithCommas";

const Progress = (props) => {

    const {apiData, t} = props
    const progress = t('common:progress', {}, {returnObjects: true})

    const [complete, setComplete] = useState(0);
    const [target, setTarget] = useState(0);
    const [percent, setPercent] = useState(0);
    const [pctLine, setPctLine] = useState(0);
    const [stageNumber, setStageNumber] = useState(1);

    useEffect(() => {
        if (apiData?.tokens_sold && apiData?.tokens_sold !== 0) {
            setPctLine((apiData?.tokens_sold * 100) / apiData?.total_tokens)
            setTarget((apiData?.stage_tokens * 100) / apiData?.total_tokens)
            setStageNumber(apiData?.stage_name?.split(' ')[1])

            setTimeout(() => {
                setComplete(+apiData?.percent_tokens)
                animateValue((val) => setPercent(val), 0, (+apiData?.percent_tokens).toFixed(2), 2000);
            }, 1000)
        } else {
            setComplete(0)
        }
    }, [apiData])

    function animateValue(cb, start, end, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            cb((progress * (end - start) + start));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    return (
        <div className='progress_section'>
            <div className='main_data'>
                <div className='stage'>
                    <h2 data-stage={progress.progress_part.live}
                        className='stage_name'>{progress.progress_part.stage} {apiData?.stage_name?.split(' ')[1]}</h2>
                </div>
                <div id='value' className='raised'>
                    <h3 className="raised_title">{progress.progress_part.raised}</h3>
                    <span className='raised_amount'>{numberWithCommasNull(Number(apiData?.tokens_sold))}
                        <span className='raised_token_name'>$PRLN /</span></span>
                    <span className='total'>{numberWithCommasNull(Number(apiData?.total_tokens))} $PRLN</span>
                </div>
            </div>
            <ProgressLine
                pct_line={pctLine}
                stageTokens={apiData?.stage_tokens}
                pct={complete}
                target={target}
                stage_number={stageNumber}
            />
            <div className='additional_data'>
                <div className='additional_data_item price'>
                    <span className='additional_data_item_title'>{progress.progress_part.price}</span>
                    <span
                        className='additional_data_item_value'>1 $PRLN ≈ {Number(apiData?.token_price)?.toFixed(4)} USDT <svg
                        xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
<path
    d="M6.13398 0.499999C6.51888 -0.166667 7.48112 -0.166667 7.86603 0.5L13.0622 9.5C13.4471 10.1667 12.966 11 12.1962 11H1.80385C1.03405 11 0.552922 10.1667 0.937822 9.5L6.13398 0.499999Z"
    fill="#29FBCD"/>
</svg></span>
                </div>
                <div className='additional_data_item bonus'>
                    <span className='additional_data_item_title'>{progress.progress_part.bonus_title}</span>
                    <div className='bonus_descr'>
                        {progress.progress_part.get}
                        <span className='gradient_text'>18% {progress.progress_part.bonus_value}</span>
                        <span className=''>{progress.progress_part.use}</span>
                        <span className='bonus_descr_code'>PRLNSTART</span>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Progress;