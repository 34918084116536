import React, {useState, useEffect} from 'react';
// import DoughnutChart from './DoughnutChart'
import dynamic from 'next/dynamic'
const DoughnutChart = dynamic(
    () => import('./DoughnutChart'),
    { ssr: false }
)

const Tokenomics = ({t}) => {

    const tokenomics = t('common:tokenomics', {}, {returnObjects: true})
    const pie_data = tokenomics.pie_data

    const [enabled, setEnabled] = useState(true);

    const setEn = () => {
        if (window.innerWidth > 576) {
            setEnabled(true)
        } else {
            setEnabled(false)
        }
    }

    useEffect(() => {

        setEn()

        window.addEventListener('resize', setEn)

        return () => {
            window.removeEventListener('resize', setEn)
        }
    }, [])

    return (
        <section className='tokenomics_section' id={'tokenomics'}>
            <div className="container">
                <h2 className='tokenomics_section_title'>{tokenomics.title}</h2>
                <div className="tokenomics_section_wrapper">
                    <div className='metrics'>
                        <h3 className='metrics_title'>{tokenomics.metrics.title}</h3>
                        <p className='metrics_description'>{tokenomics.metrics.description}</p>
                    </div>
                    <div className="chart">
                        <div className="inner">
                            <span>{tokenomics.chart_title}</span>
                            <span>640m</span>
                        </div>
                        <DoughnutChart
                            data={pie_data}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tokenomics;