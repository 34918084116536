import React from 'react';
import {numberWithCommasNull} from "../../helpers/numberWithCommas";

const ProgressLine = ({pct_line, pct, target, stage_number, stageTokens}) => {
console.log(pct)
    return (
        <div className='progress_line_wrapper'>
            <div className='progress'>
                <div data-percent={`${pct?.toFixed(2)}%`} style={{width: `${pct_line}%`}} className="progress_bar"/>
                <div data-target={`Stage ${stage_number} target (${numberWithCommasNull(stageTokens)} $PRLN)`} style={{width: `${target}%`}} className="progress_bar_target"/>
            </div>
        </div>
    );
};

export default ProgressLine;